import * as styles from './styles.scss';

import { CONTEXT, ContextData } from '@vsemayki/url-resolver';
import {
    ContextState,
    contextSelector,
} from '@store/ducks/application/context';
import React, { createContext, useContext, useEffect, useState } from 'react';

import ChevronUpIcon from '@uikit/Icons/NewDesign/ChevronUp_inline.svg';
import SearchIcon from '@uikit/Icons/search_inline.svg';
import Button from '@uikit/Button';
import Input from '@uikit/Input';
import { Link } from '@components/Link';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';
import Scrollbar from '@uikit/Scrollbar';
import { structureSelector } from '@ducks/application/structure';
import { CreateStructureTree, StructureTreeItem } from '../../Desktop/utils';
import useDebounce from '@src/hooks/useDebounce';
import useIsInView from '@src/hooks/useIsInView';
import type Scrollbars from 'react-custom-scrollbars';

const Context = createContext({} as { scrollTo: (offsetTop: number) => void });

const compareLowerCase = (...args: string[]) =>
    args.every((item) =>
        item.toLocaleLowerCase().match(args[0].toLocaleLowerCase())
    );

function filterRecursiveByChild(
    items: StructureTreeItem[],
    checkFn: (object: StructureTreeItem) => boolean
) {
    const getNodes = (
        result: StructureTreeItem[],
        object: StructureTreeItem
    ) => {
        if (checkFn(object)) {
            result.push(object);
            return result;
        }

        if (Array.isArray(object.children)) {
            const children = object.children.reduce(getNodes, []);
            if (children.length) result.push({ ...object, children });
        }
        return result;
    };

    return items.reduce(getNodes, []);
}

type SubfilterItemProps = {
    subFilter: StructureTreeItem;
    context: ContextState;
    onSelect?: FilterMobileListProps['onSelect'];
};
const SubfilterItem: React.FC<SubfilterItemProps> = React.memo(
    ({ subFilter, context: ContextState, onSelect }) => {
        const LinkContext: ContextData = {
            name: CONTEXT.catalogue,
            collection: ContextState.collection,
            filter: { type: subFilter.type, value: subFilter.id },
        };

        const isActive =
            ContextState.filter?.type === subFilter.type &&
            ContextState.filter?.value === subFilter.id;

        const { scrollTo } = useContext(Context);
        const activeRef = React.createRef<HTMLLIElement>();
        useEffect(() => {
            if (activeRef.current)
                scrollTo(
                    activeRef.current.offsetTop - window.screen.height / 2
                );
        }, [scrollTo, activeRef]);

        return (
            <li
                key={subFilter.id}
                className={clsx(styles.subfilter)}
                ref={isActive ? activeRef : null}
            >
                <Link
                    key={subFilter.id}
                    context={LinkContext}
                    nativeLinkProps={{
                        className: clsx(
                            styles.subfilterLink,
                            isActive && styles.activeSubfilter
                        ),
                        onClick: () => {
                            onSelect?.(subFilter.id);
                        },
                    }}
                >
                    {subFilter.title}
                </Link>

                {subFilter.children.length > 0 && (
                    <ul>
                        {subFilter.children.map((item) => (
                            <SubfilterItem
                                key={item.id}
                                subFilter={item}
                                context={ContextState}
                                onSelect={onSelect}
                            />
                        ))}
                    </ul>
                )}
            </li>
        );
    }
);
SubfilterItem.displayName = 'SubfilterItemMemoized';

type FilterItemProps = {
    item: StructureTreeItem;
    context: ContextState;
    search: string;
    currentlyOpen: string;
    setOpen: (id: string) => void;
    onSelect?: FilterMobileListProps['onSelect'];
};

const FilterItem: React.FC<FilterItemProps> = ({
    item,
    context,
    search,
    currentlyOpen,
    setOpen,
    onSelect,
}) => {
    const isOpen = currentlyOpen === item.id || search.length > 0;
    const haveSubfilters = item.children?.length > 0;

    const [intersectionRef, isIntersecting] = useIsInView<HTMLSpanElement>();
    const showSticky = isOpen && !isIntersecting && item.children.length > 2;

    return (
        <li key={item.id}>
            <div
                className={clsx(
                    styles.filter,
                    showSticky && styles.filterSticky
                )}
            >
                <Link
                    context={{
                        name: CONTEXT.catalogue,
                        collection: context.collection,
                        filter: { type: item.type, value: item.id },
                    }}
                    nativeLinkProps={{
                        className: styles.link,
                        onClick: () => {
                            setOpen(item.id);
                            onSelect?.(item.id);
                        },
                    }}
                >
                    {item.title}
                </Link>
                {haveSubfilters ? (
                    <Button
                        className={styles.toggleBtn}
                        onClick={() =>
                            isOpen ? setOpen('') : setOpen(item.id)
                        }
                        aria-label="Expand list"
                    >
                        <span
                            className={clsx(
                                styles.expand,
                                isOpen
                                    ? styles.expandRotateCW
                                    : styles.expandRotateCCW
                            )}
                        >
                            <ChevronUpIcon />
                        </span>
                    </Button>
                ) : (
                    <span className={styles.whitespace} />
                )}
            </div>

            <ul
                className={clsx(styles.subfilterList, !isOpen && styles.hidden)}
            >
                <span ref={intersectionRef} />
                <br />
                {isOpen &&
                    item.children.map((subFilter) => {
                        return (
                            <SubfilterItem
                                key={subFilter.id}
                                subFilter={subFilter}
                                context={context}
                                onSelect={onSelect}
                            />
                        );
                    })}
            </ul>
        </li>
    );
};

type FilterMobileListProps = { onSelect?: (id: string) => void };
const FilterMobileList: React.FC<FilterMobileListProps> = ({ onSelect }) => {
    const StructureState = useSelector(structureSelector);
    const ContextState = useSelector(contextSelector, isEqual);

    const [search, setSearch] = useState<string>('');

    const filtersTags = React.useMemo(() => {
        const StructureClone = [...StructureState];

        const Structure = CreateStructureTree(StructureClone);
        const TreeStructure = filterRecursiveByChild(Structure, (object) =>
            compareLowerCase(search, object.title)
        );

        return TreeStructure;
    }, [search]);

    const [currentlyOpen, setOpen] = useState<string>(() => {
        const filterValue = ContextState.filter?.value;
        const filteredByFilterValue = filterRecursiveByChild(
            filtersTags,
            (object) => object.id === filterValue
        );

        return filteredByFilterValue[0]?.id || '';
    });

    const FilterItems = React.useMemo(
        () =>
            filtersTags.map((filter, idx) => {
                return (
                    <FilterItem
                        item={filter}
                        context={ContextState}
                        search={search}
                        currentlyOpen={currentlyOpen}
                        setOpen={setOpen}
                        key={filter.id + idx}
                        onSelect={onSelect}
                    />
                );
            }),
        [filtersTags, ContextState, currentlyOpen]
    );

    const handleSearch = useDebounce((value: string) => setSearch(value));

    const scrollRef = React.createRef<Scrollbars>();
    const scrollTo = (offsetTop: number) => {
        if (scrollRef.current) scrollRef.current.scrollTop(offsetTop);
    };

    return (
        <>
            <Input
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Поиск по товарам"
                className={styles.search}
                aria-label="Filter search"
                rightIcon={<SearchIcon />}
            />
            <Scrollbar
                style={{ width: 'auto', height: 'auto' }}
                autoHeight={false}
                className={styles.filterList}
                autoHide={false}
                hideTracksWhenNotNeeded={false}
                ref={scrollRef}
            >
                <Context.Provider value={{ scrollTo }}>
                    <nav data-autotest="Filters" className={styles['filters']}>
                        <ul>{FilterItems}</ul>
                    </nav>
                </Context.Provider>
            </Scrollbar>
        </>
    );
};

export default React.memo(FilterMobileList);
