import * as styles from './styles.scss';

import { CONTEXT, ContextData } from '@vsemayki/url-resolver';
import {
    ContextState,
    contextSelector,
} from '@store/ducks/application/context';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TagsState, tagsSelector } from '@store/ducks/application/tags';

import MinusIcon from '@uikit/Icons/NewDesign/Minus_inline.svg';
import PlusIcon from '@uikit/Icons/NewDesign/Plus_inline.svg';
import Button from '@uikit/Button';
import Input from '@uikit/Input';
import { Link } from '@components/Link';
import Title from '@uikit/Title';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import escapeRegExp from 'lodash/escapeRegExp';
import groupBy from 'lodash/groupBy';
import debounce from 'lodash/debounce';
import useFallHeader from '@src/hooks/useFallHeader';
import { useSelector } from 'react-redux';
import Scrollbar from '@uikit/Scrollbar';
import Badge from '@uikit/Badge';
import SearchIcon from '@uikit/Icons/search_inline.svg';
import useContextMapper from '@src/hooks/useContextMapper';
import useDebounce from '@src/hooks/useDebounce';

const compareLowerCase = (...args: string[]) =>
    args.every((item) =>
        item
            .toLocaleLowerCase()
            .match(escapeRegExp(args[0].toLocaleLowerCase()))
    );

type SubcategoryItemProps = {
    subCategory: TagsState[0];
    context: ContextState;
};
const SubcategoryItem: React.FC<SubcategoryItemProps> = React.memo(
    ({ subCategory, context: ContextState }) => {
        const [category, subcategory] = subCategory.id.split('__');

        const LinkContext: ContextData = {
            name: CONTEXT.catalogue,
            collection: {
                category,
                subCategory: subcategory,
            },
            filter: ContextState.filter,
        };

        const isActive =
            ContextState.collection?.category === category &&
            ContextState.collection.subCategory === subcategory;

        return (
            <Link key={subCategory.id} context={LinkContext}>
                <li
                    key={subCategory.id}
                    className={clsx(
                        styles.subcategory,
                        isActive && styles.activeSubcategory
                    )}
                >
                    {subCategory.title}
                </li>
            </Link>
        );
    }
);
SubcategoryItem.displayName = 'SubcategoryItemMemoized';

type CategoryItemProps = {
    categories: TagsState;
    context: ContextState;
    search: string;
    currentlyOpen: string;
    setOpen: (id: string) => void;
};

const CategoryItem: React.FC<CategoryItemProps> = ({
    categories,
    context,
    search,
    currentlyOpen,
    setOpen,
}) => {
    const subcategoryListRef = useRef<HTMLUListElement>(null);

    const ScrollDebounced = useDebounce(() => {
        subcategoryListRef.current &&
            'scrollIntoViewIfNeeded' in subcategoryListRef.current &&
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (subcategoryListRef.current as any).scrollIntoViewIfNeeded();
    });

    const categoriesClone = [...categories];
    const header = categoriesClone.shift();
    if (!header) return null;
    const isOpen = currentlyOpen === header.id || search;
    const haveSubcategories = categoriesClone.length > 0;

    useEffect(() => {
        isOpen && ScrollDebounced();
    }, [isOpen]);

    const currentActiveSubcategory =
        context &&
        categoriesClone.find((subcat) => {
            const [category, subcategory] = subcat.id.split('__');
            return (
                context.collection?.category === category &&
                context.collection.subCategory === subcategory
            );
        });

    return (
        <li>
            <div className={clsx(styles.category)}>
                {haveSubcategories ? (
                    <Button
                        className={styles.toggleBtn}
                        onClick={() => {
                            isOpen ? setOpen('') : setOpen(header.id);
                        }}
                        aria-label="Expand list"
                    >
                        <span
                            className={clsx(
                                styles.expand,
                                isOpen
                                    ? styles.expandRotateCW
                                    : styles.expandRotateCCW
                            )}
                        >
                            {isOpen ? <MinusIcon /> : <PlusIcon />}
                        </span>
                    </Button>
                ) : (
                    <span className={styles.whitespace} />
                )}
                <Link
                    context={{
                        name: CONTEXT.catalogue,
                        collection: {
                            category: header.id,
                            subCategory: '',
                        },
                        filter: context.filter,
                    }}
                    nativeLinkProps={{
                        className: styles.link,
                        onClick: () => setOpen(header.id),
                    }}
                >
                    {header.title}
                </Link>
            </div>
            {!isOpen && currentActiveSubcategory && (
                <div className={styles.category}>
                    <span className={styles.whitespace} />
                    <Badge
                        type="badge"
                        className={clsx(styles.subcategoryBadge)}
                    >
                        {currentActiveSubcategory.title}
                    </Badge>
                </div>
            )}
            <Scrollbar
                withFade
                style={{ width: 'auto', height: isOpen ? 'auto' : 0 }}
                autoHeightMax={245}
                className={styles.subcategoryList}
            >
                <ul
                    className={clsx(!isOpen && styles.hidden)}
                    ref={subcategoryListRef}
                >
                    {isOpen &&
                        categoriesClone.map((subCategory) => {
                            return (
                                <SubcategoryItem
                                    key={subCategory.id}
                                    subCategory={subCategory}
                                    context={context}
                                />
                            );
                        })}
                </ul>
            </Scrollbar>
        </li>
    );
};

const Categories: React.FC = () => {
    const TagsState = useSelector(tagsSelector);
    const ContextState = useSelector(contextSelector, isEqual);
    const MappedContext = useContextMapper();
    const isFallHeader = useFallHeader();

    const [filter, setFilter] = useState<string>('');
    const [currentlyOpen, setOpen] = useState<string>(
        ContextState.collection?.category ?? ''
    );

    const groupedTags = React.useMemo(
        () =>
            groupBy(TagsState, (item) => (item.parent ? item.parent : item.id)),
        [TagsState]
    );

    const filtredTags = React.useMemo(
        () =>
            Object.values(groupedTags).reduce<typeof TagsState[]>(
                (acc, category) => {
                    if (
                        category.some((item) =>
                            compareLowerCase(filter, item.title)
                        )
                    ) {
                        const filtredCat = category.filter(
                            (item) =>
                                !item.parent ||
                                compareLowerCase(filter, item.title)
                        );
                        acc.push(filtredCat);
                    }
                    return acc;
                },
                []
            ),
        [groupedTags, filter]
    );

    const CategoryItems = React.useMemo(
        () =>
            filtredTags.map((categories) => {
                return (
                    <CategoryItem
                        categories={categories}
                        context={ContextState}
                        search={filter}
                        currentlyOpen={currentlyOpen}
                        setOpen={setOpen}
                        key={categories[0].id}
                    />
                );
            }),
        [filtredTags, ContextState, currentlyOpen]
    );

    const handleFilter = useCallback(
        debounce((value: string) => {
            setFilter(value);
        }, 300),
        []
    );

    return (
        <div
            className={clsx(
                styles.wrapper,
                !isFallHeader && styles.wrapperFullsize
            )}
        >
            <header className={styles.categoryHeader}>
                <Title className={styles.categoryTitle}>Тематика</Title>
                {MappedContext.structure?.title && (
                    <span className={styles.categorySubtitle}>
                        для категории «{MappedContext.structure?.title}»
                    </span>
                )}
            </header>
            <Input
                onChange={(e) => handleFilter(e.target.value)}
                placeholder="Поиск по тематикам"
                className={styles.search}
                aria-label="Category search"
                rightIcon={<SearchIcon />}
            />
            <Scrollbar
                style={{ width: 'auto', height: 'auto' }}
                autoHeight={false}
                className={styles.categoryList}
                withFade
            >
                <nav data-autotest="Categories">
                    <ul>{CategoryItems}</ul>
                </nav>
            </Scrollbar>
        </div>
    );
};

export default React.memo(Categories);
