import { useMemo, useRef } from 'react';
import usePageScroll from './usePageScroll';

const usePageIsScrolledDown = (): boolean => {
    if (!process.browser) return false;

    const prevScrollPos = useRef(document.documentElement.scrollTop);
    const prevScrollHeight = useRef(document.documentElement.scrollHeight);
    const [currentScrollPos, currentScrollHeight] = usePageScroll();

    const isScrolledDown = useMemo(() => {
        const delta =
            prevScrollHeight.current - prevScrollPos.current >
            currentScrollHeight - currentScrollPos;

        prevScrollPos.current = currentScrollPos;
        prevScrollHeight.current = currentScrollHeight;

        return delta;
    }, [currentScrollPos, currentScrollHeight]);

    return isScrolledDown;
};
export default usePageIsScrolledDown;
