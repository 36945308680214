import * as React from 'react';
import CategoriesMobileList from './List';
import Modal from '@components/Modal';
import useTypedDispatch from '@src/hooks/useTypedDispatch';
import { dialogActions } from '@ducks/dialog';
import styles from './catalogMobile.module.scss';
import ChevronRightIcon from '@uikit/Icons/NewDesign/ChevronRight_inline.svg';
import CloseIcon from '@uikit/Icons/closeBtn_inline.svg';
import { useSelector } from 'react-redux';
import { contextSelector } from '@ducks/application/context';
import { tagsSelector } from '@ducks/application/tags';
import clsx from 'clsx';
import Link from '@components/Link';

type CategoriesMobileProps = JSX.IntrinsicElements['div'] & {
    showReset?: boolean;
};
const CategoriesMobile: React.FC<CategoriesMobileProps> = ({
    showReset,
    ...props
}) => {
    const dispatch = useTypedDispatch();
    const ContextState = useSelector(contextSelector);
    const TagsState = useSelector(tagsSelector);

    const category = TagsState.find((item) => {
        if (!ContextState.collection) return;
        const { category, subCategory } = ContextState.collection;
        return (
            item.id === (subCategory ? `${category}__${subCategory}` : category)
        );
    });
    const haveCategory = Boolean(category?.id);

    const handleItemSelect = React.useCallback(() => {
        dispatch(dialogActions.close('mobileCategories'));
    }, []);

    return (
        <>
            <div
                {...props}
                className={clsx(
                    styles.catalogMobile,
                    haveCategory && styles.catalogMobileActive,
                    props.className
                )}
            >
                <div
                    tabIndex={0}
                    className={clsx(
                        styles.textWrapper,
                        !haveCategory && styles.textWrapperWithRightPadding
                    )}
                    onClick={(event) => {
                        props.onClick?.(event);
                        dispatch(dialogActions.open('mobileCategories'));
                    }}
                >
                    {haveCategory && <span className={styles['shadow']} />}
                    <span>{haveCategory ? category?.title : 'Тематика'}</span>
                    <ChevronRightIcon
                        className={clsx(
                            styles['icon'],
                            haveCategory && styles['iconChevron']
                        )}
                    />
                </div>
                {showReset && haveCategory && (
                    <Link
                        context={{ ...ContextState, collection: undefined }}
                        nativeLinkProps={{
                            className: styles.clear,
                        }}
                    >
                        <CloseIcon className={styles['icon']} />
                    </Link>
                )}
            </div>
            <Modal title="Тематика" type="mobileCategories" fullscreen>
                <CategoriesMobileList onSelect={handleItemSelect} />
            </Modal>
        </>
    );
};

export default CategoriesMobile;
