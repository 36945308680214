import { StructureState, StructureItem } from '@ducks/application/structure';

export type StructureTreeItem = StructureItem & {
    children: StructureTreeItem[];
};

export const CreateStructureWithChildrens = (
    items: StructureState
): Record<string, StructureTreeItem> => {
    //Its all about the pointers, nested object in the objects shares by pointer, not the value, but we have O(n);
    const lookup: Record<string, StructureTreeItem> = {};

    for (const item of items) {
        const itemId = item.id;
        const parentId = item.parent;

        lookup[itemId] = {
            ...item,
            children: !lookup[itemId] ? [] : lookup[itemId].children,
        };

        const TreeItem = lookup[itemId];

        if (!(parentId === null || parentId === undefined)) {
            lookup[parentId].children.push(TreeItem);
        }
    }

    return lookup;
};

export const CreateStructureTree = (
    items: StructureState
): StructureTreeItem[] => {
    const rootItems: StructureTreeItem[] = [];

    //Its all about the pointers, nested object in the objects shares by pointer, not the value, but we have O(n);
    const lookup: Record<string, StructureTreeItem> = {};

    for (const item of items) {
        const itemId = item.id;
        const parentId = item.parent;

        lookup[itemId] = {
            ...item,
            children: !lookup[itemId] ? [] : lookup[itemId].children,
        };

        const TreeItem = lookup[itemId];

        if (parentId === null || parentId === undefined) {
            rootItems.push(TreeItem);
        } else {
            lookup[parentId].children.push(TreeItem);
        }
    }

    return rootItems;
};
