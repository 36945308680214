import { RefObject, useEffect, useRef, useState } from 'react';

type UseIsInViewProps = {
    rootMargin?: string;
    observerOptions?: IntersectionObserverInit;
    observerCb?: (entity: IntersectionObserverEntry) => void;
};

const useIsInView = <T extends HTMLElement>({
    observerOptions,
    observerCb,
}: UseIsInViewProps = {}): [RefObject<T>, boolean] => {
    const [isIntersecting, setIntersecting] = useState(false);
    const ref = useRef<T>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIntersecting(entry.isIntersecting);
            observerCb && observerCb(entry);
        }, observerOptions);
        if (ref.current) observer.observe(ref.current);

        return () => {
            if (ref.current) observer.unobserve(ref.current);
        };
    }, [ref]);

    return [ref, isIntersecting];
};

export default useIsInView;
