import * as React from 'react';
import FilterMobileList from './List';
import Modal from '@components/Modal';
import useTypedDispatch from '@src/hooks/useTypedDispatch';
import { dialogActions } from '@ducks/dialog';
import styles from './filterMobile.module.scss';
import ChevronRightIcon from '@uikit/Icons/NewDesign/ChevronRight_inline.svg';
import CloseIcon from '@uikit/Icons/closeBtn_inline.svg';
import { useSelector } from 'react-redux';
import { contextSelector } from '@ducks/application/context';
import clsx from 'clsx';
import { structureSelector } from '@ducks/application/structure';
import Link from '@components/Link';

type FilterMobileProps = JSX.IntrinsicElements['div'] & { showReset?: boolean };
const FilterMobile: React.FC<FilterMobileProps> = ({
    showReset = false,
    ...props
}) => {
    const dispatch = useTypedDispatch();
    const ContextState = useSelector(contextSelector);
    const StructureState = useSelector(structureSelector);

    const filter = StructureState.find(
        (item) => item.id === ContextState.filter?.value
    );
    const haveFilter = Boolean(filter?.id);

    const handleItemSelect = React.useCallback(() => {
        dispatch(dialogActions.close('mobileFilter'));
    }, []);

    return (
        <>
            <div
                {...props}
                className={clsx(
                    styles.filterMobile,
                    haveFilter && styles.filterMobileActive,
                    props.className
                )}
            >
                <div
                    tabIndex={0}
                    className={clsx(
                        styles.textWrapper,
                        !haveFilter && styles.textWrapperWithRightPadding
                    )}
                    onClick={(event) => {
                        props.onClick?.(event);
                        dispatch(dialogActions.open('mobileFilter'));
                    }}
                >
                    {haveFilter && <span className={styles['shadow']} />}
                    <span>{haveFilter ? filter?.title : 'Вид товара'}</span>
                    <ChevronRightIcon
                        className={clsx(
                            styles['icon'],
                            haveFilter && styles['iconChevron']
                        )}
                    />
                </div>
                {showReset && haveFilter && (
                    <Link
                        context={{ ...ContextState, filter: undefined }}
                        nativeLinkProps={{
                            className: styles.clear,
                        }}
                    >
                        <CloseIcon className={styles['icon']} />
                    </Link>
                )}
            </div>

            <Modal title="Вид товара" type="mobileFilter" fullscreen>
                <FilterMobileList onSelect={handleItemSelect} />
            </Modal>
        </>
    );
};

export default FilterMobile;
