import * as styles from './styles.scss';

import { CONTEXT, ContextData } from '@vsemayki/url-resolver';
import {
    ContextState,
    contextSelector,
} from '@store/ducks/application/context';
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { TagsState, tagsSelector } from '@store/ducks/application/tags';
import { debounce, escapeRegExp, groupBy } from 'lodash';

import ChevronUpIcon from '@uikit/Icons/NewDesign/ChevronUp_inline.svg';
import SearchIcon from '@uikit/Icons/search_inline.svg';
import Button from '@uikit/Button';
import Input from '@uikit/Input';
import { Link } from '@components/Link';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';
import Scrollbar from '@uikit/Scrollbar';
import Badge from '@uikit/Badge';
import useIsInView from '@src/hooks/useIsInView';
import type Scrollbars from 'react-custom-scrollbars';

const Context = createContext({} as { scrollTo: (offsetTop: number) => void });

const compareLowerCase = (...args: string[]) =>
    args.every((item) =>
        item
            .toLocaleLowerCase()
            .match(escapeRegExp(args[0].toLocaleLowerCase()))
    );

type SubcategoryItemProps = {
    subCategory: TagsState[0];
    context: ContextState;
    onSelect?: CategoriesMobileListProps['onSelect'];
};
const SubcategoryItem: React.FC<SubcategoryItemProps> = React.memo(
    ({ subCategory, context: ContextState, onSelect }) => {
        const [category, subcategory] = subCategory.id.split('__');

        const LinkContext: ContextData = {
            name: CONTEXT.catalogue,
            collection: {
                category,
                subCategory: subcategory,
            },
            filter: ContextState.filter,
        };

        const isActive =
            ContextState.collection?.category === category &&
            ContextState.collection.subCategory === subcategory;

        const { scrollTo } = useContext(Context);
        const activeRef = React.createRef<HTMLLIElement>();
        useEffect(() => {
            if (activeRef.current)
                scrollTo(
                    activeRef.current.offsetTop - window.screen.height / 2
                );
        }, [scrollTo, activeRef]);

        return (
            <Link
                key={subCategory.id}
                context={LinkContext}
                nativeLinkProps={{ onClick: () => onSelect?.(subCategory.id) }}
            >
                <li
                    key={subCategory.id}
                    className={clsx(
                        styles.subcategory,
                        isActive && styles.activeSubcategory
                    )}
                    ref={isActive ? activeRef : null}
                >
                    {subCategory.title}
                </li>
            </Link>
        );
    }
);
SubcategoryItem.displayName = 'SubcategoryItemMemoized';

type CategoryItemProps = {
    categories: TagsState;
    context: ContextState;
    filter: string;
    currentlyOpen: string;
    setOpen: (id: string) => void;
    onSelect?: CategoriesMobileListProps['onSelect'];
};

const CategoryItem: React.FC<CategoryItemProps> = ({
    categories,
    context,
    filter,
    currentlyOpen,
    setOpen,
    onSelect,
}) => {
    const categoriesClone = [...categories];
    const header = categoriesClone.shift();
    if (!header) return null;
    const isOpen = currentlyOpen === header.id || filter;
    const haveSubcategories = categoriesClone.length > 0;

    const currentActiveSubcategory =
        context &&
        categoriesClone.find((subcat) => {
            const [category, subcategory] = subcat.id.split('__');
            return (
                context.collection?.category === category &&
                context.collection.subCategory === subcategory
            );
        });

    const [intersectionRef, isIntersecting] = useIsInView<HTMLSpanElement>();
    const showSticky = isOpen && !isIntersecting && categoriesClone.length > 2;

    return (
        <li key={header.id}>
            <div
                className={clsx(
                    styles.category,
                    showSticky && styles.categorySticky
                )}
            >
                <Link
                    context={{
                        name: CONTEXT.catalogue,
                        collection: {
                            category: header.id,
                            subCategory: '',
                        },
                        filter: context.filter,
                    }}
                    nativeLinkProps={{
                        className: styles.link,
                        onClick: () => {
                            setOpen(header.id);
                            onSelect?.(header.id);
                        },
                    }}
                >
                    {header.title}
                </Link>
                {haveSubcategories ? (
                    <Button
                        className={styles.toggleBtn}
                        onClick={() =>
                            isOpen ? setOpen('') : setOpen(header.id)
                        }
                        aria-label="Expand list"
                    >
                        <span
                            className={clsx(
                                styles.expand,
                                isOpen
                                    ? styles.expandRotateCW
                                    : styles.expandRotateCCW
                            )}
                        >
                            <ChevronUpIcon />
                        </span>
                    </Button>
                ) : (
                    <span className={styles.whitespace} />
                )}
            </div>

            {!isOpen && currentActiveSubcategory && (
                <div className={styles.category}>
                    <Badge
                        type="badge"
                        className={clsx(styles.subcategoryBadge)}
                    >
                        {currentActiveSubcategory.title}
                    </Badge>
                </div>
            )}

            <ul
                className={clsx(
                    styles.subcategoryList,
                    !isOpen && styles.hidden
                )}
            >
                <span ref={intersectionRef} />
                {isOpen &&
                    categoriesClone.map((subCategory) => {
                        return (
                            <SubcategoryItem
                                key={subCategory.id}
                                subCategory={subCategory}
                                context={context}
                                onSelect={onSelect}
                            />
                        );
                    })}
            </ul>
        </li>
    );
};

type CategoriesMobileListProps = { onSelect?: (id: string) => void };
const CategoriesMobileList: React.FC<CategoriesMobileListProps> = ({
    onSelect,
}) => {
    const TagsState = useSelector(tagsSelector);
    const ContextState = useSelector(contextSelector, isEqual);

    const [filter, setFilter] = useState<string>('');
    const [currentlyOpen, setOpen] = useState<string>(
        ContextState.collection?.category ?? ''
    );

    const groupedTags = React.useMemo(
        () =>
            groupBy(TagsState, (item) => (item.parent ? item.parent : item.id)),
        [TagsState]
    );

    const filtredTags = React.useMemo(
        () =>
            Object.values(groupedTags).reduce<typeof TagsState[]>(
                (acc, category) => {
                    if (
                        category.some((item) =>
                            compareLowerCase(filter, item.title)
                        )
                    ) {
                        const filtredCat = category.filter(
                            (item) =>
                                !item.parent ||
                                compareLowerCase(filter, item.title)
                        );
                        acc.push(filtredCat);
                    }
                    return acc;
                },
                []
            ),
        [groupedTags, filter]
    );

    const CategoryItems = React.useMemo(
        () =>
            filtredTags.map((categories) => {
                return (
                    <CategoryItem
                        categories={categories}
                        context={ContextState}
                        filter={filter}
                        currentlyOpen={currentlyOpen}
                        setOpen={setOpen}
                        key={categories[0].id}
                        onSelect={onSelect}
                    />
                );
            }),
        [filtredTags, ContextState, currentlyOpen]
    );

    const handleFilter = useCallback(
        debounce((value: string) => {
            setFilter(value);
        }, 300),
        []
    );

    const scrollRef = React.createRef<Scrollbars>();
    const scrollTo = (offsetTop: number) => {
        if (scrollRef.current) scrollRef.current.scrollTop(offsetTop);
    };

    return (
        <>
            <Input
                onChange={(e) => handleFilter(e.target.value)}
                placeholder="Поиск по тематикам"
                className={styles.search}
                aria-label="Category search"
                rightIcon={<SearchIcon />}
            />
            <Scrollbar
                style={{ width: 'auto', height: 'auto' }}
                autoHeight={false}
                className={styles.categoryList}
                autoHide={false}
                hideTracksWhenNotNeeded={false}
                ref={scrollRef}
            >
                <Context.Provider value={{ scrollTo }}>
                    <nav
                        data-autotest="Categories"
                        className={styles['categories']}
                    >
                        <ul>{CategoryItems}</ul>
                        <div className={styles['shadow']} />
                    </nav>
                </Context.Provider>
            </Scrollbar>
        </>
    );
};

export default React.memo(CategoriesMobileList);
