import * as React from 'react';
import CategoriesMobile from '@components/Categories/Mobile';
import FilterMobile from '@components/Catalog/Filter/Mobile';
import useIsInView from '@src/hooks/useIsInView';
import styles from './mobileControls.module.scss';
import clsx from 'clsx';

type CatalogMobileControlsProps = JSX.IntrinsicElements['div'];
const CatalogMobileControls = (props: CatalogMobileControlsProps) => {
    const [ref, isIntersecting] = useIsInView<HTMLDivElement>();

    return (
        <>
            <div
                {...props}
                className={clsx(styles.controls, props.className)}
                ref={ref}
            >
                <CategoriesMobile className={styles.controlsItem} showReset />
                <FilterMobile className={styles.controlsItem} showReset />
            </div>
            <div
                {...props}
                className={clsx(
                    styles.fixed,
                    !isIntersecting && styles.fixedActive
                )}
            >
                <CategoriesMobile className={styles.fixedControlsItem} />
                <FilterMobile className={styles.fixedControlsItem} />
            </div>
        </>
    );
};

export default CatalogMobileControls;
