import { RootState } from '@ducks/index';
import { contextSelector, ContextState } from '@ducks/application/context';
import {
    structureSelector,
    StructureState,
} from '@ducks/application/structure';
import { tagsSelector, TagsState } from '@ducks/application/tags';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const ContextMapperSelector = createSelector<
    RootState,
    ContextState,
    StructureState,
    TagsState,
    {
        collection: TagsState[0] | undefined;
        subcategory: TagsState[0] | undefined;
        structure: StructureState[0] | undefined;
    }
>(
    contextSelector,
    structureSelector,
    tagsSelector,
    (context, structure, tags) => {
        return {
            collection:
                context.collection &&
                tags.find((el) => el.id === context.collection?.category),
            subcategory:
                context.collection &&
                tags.find(
                    (el) =>
                        el.id ===
                        `${context.collection?.category}__${context.collection?.subCategory}`
                ),
            structure: context.filter?.value
                ? structure.find(
                      (struct) => struct.id === context.filter?.value
                  )
                : undefined,
        };
    }
);

const useContextMapper = () => useSelector(ContextMapperSelector);

export default useContextMapper;
