import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunkAction } from '..';
import { APIShopSettingsResponse } from '@server/routes/shopSettings';
import { ApiMiddleware } from '@vsemayki/shared-frontend';

const { apiRequest } = ApiMiddleware;

export type ShopSettingsState = APIShopSettingsResponse & {
    isLoaded: boolean;
    shopLogo: string;
    phone: string;
    email?: string | null;
};

const initialState: ShopSettingsState = {
    title: 'VSEMAYKI SHOP',
    isLoaded: false,
    shopLogo: '',
    phone: '8-800-200-4660',
    email: null,
};

const shopSettings = createSlice({
    name: 'ShopSettings',
    initialState,
    reducers: {
        setSettings: (_state, action: PayloadAction<ShopSettingsState>) =>
            action.payload,
        setShopLogo: (
            state,
            action: PayloadAction<ShopSettingsState['shopLogo']>
        ) => {
            state.shopLogo = action.payload;
        },
    },
});

export const shopSettingsActions = shopSettings.actions;

export default shopSettings.reducer;

export const shopSettingsSelector = (state: RootState) =>
    state.application.shopSettings;

export const shopSettingsIsLeftMenuHiddenSelector = (state: RootState) =>
    shopSettingsSelector(state).isSystemCategoriesHidden;

export const getShopSettings: AppThunkAction = () => async (
    dispatch,
    getState
) => {
    try {
        const State = getState();
        const ShopSettingsState = shopSettingsSelector(State);

        if (ShopSettingsState.isLoaded) return;
        const response = await dispatch<APIShopSettingsResponse>(
            apiRequest({ url: '/shopSettings' })
        );

        const shopLogo =
            JSON.parse(response.templateSettings ?? '{}')?.head?.logo?.url ??
            '';

        const phone = (() => {
            if (response.id === 1127651) {
                return '8 906 015 89 56';
            }
            if (response.isWhiteLabel) {
                return '89039389080';
            }
            return '8-800-200-4660';
        })();

        const email = (() => {
            if (response.id === 1127651) {
                return 'theminters@yandex.ru';
            }
            if (response.isWhiteLabel) {
                return 'b0risov.ab@yandex.ru';
            }
            return null;
        })();

        dispatch(
            shopSettingsActions.setSettings({
                ...response,
                isLoaded: true,
                shopLogo,
                phone,
                email,
            })
        );
    } catch (error) {
        console.error('cant get shopSettings :>> ', error);
    }
};
