import React from 'react';
import clsx from 'clsx';
import * as styles from './styles.scss';

type BadgeProps = { type: keyof typeof styles; className?: string };
const Badge: React.FC<BadgeProps> = (props) => {
    return (
        <span className={clsx(styles[props.type], props.className)}>
            {props.children}
        </span>
    );
};

export default Badge;
