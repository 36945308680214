import React, { ReactNode } from 'react';
import clsx from 'clsx';
import * as styles from './styles.scss';

export type InputProps = {
    title?: ReactNode;
    rightIcon?: ReactNode;
    leftIcon?: ReactNode;
    haveError?: boolean;
    helperText?: ReactNode;
} & JSX.IntrinsicElements['input'];

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const {
        rightIcon,
        leftIcon,
        title,
        className,
        haveError,
        helperText,
        required,
        ...inputProps
    } = props;
    return (
        <span className={clsx(styles.wrapper, className)}>
            {title && (
                <label htmlFor={title} className={styles.title}>
                    {title}{' '}
                    {required && <span className={styles.alarm}>*</span>}
                </label>
            )}
            <div className={styles.inputGrid}>
                {leftIcon && (
                    <span className={styles.leftIcon}>{leftIcon}</span>
                )}
                <input
                    type="text"
                    {...inputProps}
                    ref={ref}
                    id={title}
                    className={clsx(
                        styles.input,
                        haveError && styles.inputError,
                        leftIcon && styles.withLeftIcon,
                        rightIcon && styles.withRightIcon
                    )}
                />
                {rightIcon && (
                    <span className={styles.rightIcon}>{rightIcon}</span>
                )}
            </div>
            {helperText && (
                <p
                    className={clsx(
                        styles.helperText,
                        haveError && styles.alarm
                    )}
                >
                    {helperText}
                </p>
            )}
        </span>
    );
});

Input.displayName = 'LiteShopInput';

export default Input;
