import * as React from 'react';
import {
    Scrollbars,
    ScrollbarProps as CustomScrollbarProps,
    positionValues,
} from 'react-custom-scrollbars';
import clsx from 'clsx';
import styles from './scrollbar.module.scss';

const ThumbsDefault = () => <div className={styles.thumb} />;

type ScrollbarProps = React.PropsWithChildren<
    CustomScrollbarProps & { withFade?: boolean }
>;
const Scrollbar = React.forwardRef<Scrollbars, ScrollbarProps>(
    (
        { children, withFade, className, ...scrollbarProps },
        ref
    ): React.ReactElement => {
        const [fadeTop, setFadeTop] = React.useState(false);
        const [fadeBottom, setFadeBottom] = React.useState(false);

        const handleScrollUpdate = React.useCallback(
            (values: positionValues) => {
                scrollbarProps.onUpdate && scrollbarProps.onUpdate(values);
                const haveScroll = values.scrollHeight > values.clientHeight;

                if (!withFade || !haveScroll) {
                    setFadeTop(false);
                    setFadeBottom(false);
                    return;
                }

                const { top } = values;
                setFadeTop(top > 0);
                setFadeBottom(top < 1);
            },
            [ref]
        );

        return (
            <Scrollbars
                ref={ref}
                autoHeight
                autoHide
                hideTracksWhenNotNeeded
                universal
                renderThumbVertical={ThumbsDefault}
                className={clsx(className, {
                    [styles.fadeTop]: fadeTop,
                    [styles.fadeBottom]: fadeBottom,
                })}
                {...scrollbarProps}
                onUpdate={handleScrollUpdate}
            >
                {children}
            </Scrollbars>
        );
    }
);

Scrollbar.displayName = 'UIKit/Scrollbar';

export default Scrollbar;
