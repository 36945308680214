import { useEffect, useMemo, useState } from 'react';

import usePageIsScrolledDown from './usePageIsScrolledDown';
import usePageScroll from './usePageScroll';

const useFallHeader = (): boolean => {
    const isScrolledDown = usePageIsScrolledDown();
    const [currentScrollPos] = usePageScroll();

    const [threshold, setThreshold] = useState(false);

    useEffect(() => {
        if (currentScrollPos > 1200) setThreshold(true);
        if (currentScrollPos < 140) setThreshold(false);
    }, [currentScrollPos]);

    const scrollBackPosition = useMemo(
        () => (!isScrolledDown && threshold ? currentScrollPos : null),
        [isScrolledDown]
    );
    const isFallOut = useMemo(
        () =>
            !!scrollBackPosition && scrollBackPosition - currentScrollPos >= 500
                ? true
                : false,
        [currentScrollPos, scrollBackPosition]
    );

    return isFallOut;
};

export default useFallHeader;
