import { throttle } from 'lodash';
import { useState, useEffect, useCallback } from 'react';

/**@returns [currentScrollPos, currentScrollHeight]  */
const usePageScroll = (): [number, number] => {
    if (!process.browser) return [0, 0];

    const [currentScroll, setCurrentScroll] = useState<[number, number]>([
        0,
        0,
    ]);

    const handleScroll = useCallback(
        throttle(() => {
            setCurrentScroll([
                document.documentElement.scrollTop,
                document.documentElement.scrollHeight,
            ]);
        }, 500),
        []
    );

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return currentScroll;
};
export default usePageScroll;
